export default {
  EN: {
    "topheader.compare.help": "Need help? Check out our video guides.",
    "carousel.item1.h2": "Save Time",
    "carousel.item2.h2": "Save Money",
    "carousel.item3.h2": "Save for your Future",
    "carousel.item1.p":
      "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
    "carousel.item2.p":
      "Learn how to save money by comparing money transfer providers and using the SaverEurope calculators",
    "carousel.item3.p":
      "Learn how to save for your future and improve your finances through our finance basics guide",
    "button.start": "Start",
    "topnav.compare.form.h1": "Compare Rates",
    "topheader.compare.form.h2": "Save on money transfers",
    "topheader.compare.form.p":
      "Compare costs of companies offering money transfer services",
    "label.country_from": "Send money from",
    "label.country_to": "Send to",
    "label.how_much": "How much",
    "button.compare": "Compare rates now",
    "topheader.compare.list.h2": "Comparison Results",
    "topheader.compare.list.green": "Results live as of today, {date_today}. ",
    "topheader.compare.list.amber": "Results collected on {date_updated}. ",
    "topheader.compare.list.red":
      "Results collected via mystery shopping on {date_updated}. ",
    "topheader.compare.list.p":
      "Send money from {country_from} to {country_to} with SaverEurope",
    "bottomheader.compare.list.p":
      "These results may not include all services available for this remittance corridor. The above information includes all known costs.",
    "topnav.resource.h1": "Finance Basics",
    "topheader.resource.form.h2": "Finance Basics",
    "topheader.resource.form.p":
      "To help you save or send your money, please read our Finance Basics tips.",
    "topnav.calculators.h1": "Calculators",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Budget Calculator",
    "card2.calculators.p":
      "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Pay period",
    "label.amount": "Amount",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Total Expenses",
    "label.total_income": "Total Income",
    "label.money_left": "Money left over",
    "label.daily": "Daily",
    "label.weekly": "Weekly",
    "label.monthly": "Monthly",
    "label.item": "Item",
    "label.primary_income": "Primary Income",
    "label.market_rate":
      "The mid market FX is {market_rate} for live data results",
    "label.operator": "Operator",
    "label.type": "Form of transfer",
    "label.receiving": "Receiving",
    "label.rate": "FX rate",
    "label.fee": "Fee",
    "label.form": "Form",
    "label.method": "Method of transfer",
    "label.speed": "Speed of transfer",
    "label.number_overtime": "Number of hours worked overtime",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Video Guides",
    "topheader.video.form.p":
      "Please use our video guides to help you understand how to use the SaverEurope App functions.",
    "bottom.faq.p":
      "SaverEurope has the answers to most of your questions but if you need more information, please contact us at <a>enquiries@saver.global</a> and we will try to help you.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Sorry, we can't find the screen you are looking for. Use the navigation below.",
    "topheader.summary.h2": "Summary",
    "topheader.summary.p":
      "Below you can see how much money you should receive for this period.",
    "label.no_results":
      "No results containing all your filter terms were found.",
    "button.back": "Back",
    "button.prev": "Prev",
    "button.next": "Next",
    "button.remove": "Remove",
    "button.add_income": "Add income",
    "button.add_expense": "Add expense",
    "button.read_more": "Read more",
    "button.show_less": "Show less",
    "topnav.budget.h1": "Budget Calculator",
    "topheader.income.h2": "Income",
    "topheader.income.p":
      "Let's start with entering all the ways you earn money.",
    "topheader.expense.h2": "Expenses",
    "topheader.expense.p":
      "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
    "topheader.budget_summary.p":
      "Well done on completing your budget plan, let’s see how much money you’ve saved.",
    "card.other_income.h4": "Other Income",
    "card.other_income.p":
      "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
    "topnav.setting.h1": "Settings",
    "label.language": "Language",
    "label.currency": "Currency",
    "label.push_notification": "Push Notifications",
    "label.language.desc": "Select the app language",
    "label.currency.desc": "Select the app currency",
    "label.push_notification.desc": "Allow app to push notification",
    "label.not_supported": "Not supported",
    "button.view_all_operators": "View all operators",
    "logo.supported.h3": "Supported by",
    "logo.managed.h3": "Managed by",
    "label.view_legend": "View legend",
    "label.legend": "Legend",
    "menu.about": "About",
    "menu.privacy_policy": "Privacy Policy",
    "menu.terms_and_conditions": "Terms and Conditions",
    "menu.disclaimer": "Disclaimer",
    "label.january": "January",
    "label.february": "‌February",
    "label.march": "March",
    "label.april": "April",
    "label.may": "‌May",
    "label.june": "June",
    "label.july": "July",
    "label.august": "August",
    "label.september": "September",
    "label.october": "‌October",
    "label.november": "November",
    "label.december": "December",
    "error.primary_income": "The primary income is required",
    "error.basic_wage": "The basic wage is required",
    "button.apply": "Apply",
    "button.clear": "Clear",
    "economic.hardship":
      "Possible economic hardship may occur if you continue to spend at this rate.",
    "play.video": "Play Video",
    "label.download": "Download",
    "label.download_summary": "Download Summary",
    "label.phone": "Phone",
    "label.fax": "Fax",
    "label.email": "Email",
    "label.website": "Website",
    "button.send_money": "Send Money",
    "label.fx": "FX",
    "label.more_options_available": "More transfer options available",
    "label.send_method": "Send Method",
    "label.receive_method": "Receive Method",
    "button.more_details": "More Details",
    "label.no_additional_fees": "No additional fees",
    "label.additional_fee": "* Receiving fees may apply",
    "onesignal.promptOptions.actionMessage":
      "We'd like to send you push notifications. You can unsubscribe at any time.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverEurope notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifications will appear on your desktop",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverEurope notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifications will appear on your device",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(you can unsubscribe anytime)",
    "onesignal.promptOptions.acceptButton": "ALLOW",
    "onesignal.promptOptions.acceptButtonText": "ALLOW",
    "onesignal.promptOptions.cancelButton": "NO THANKS",
    "onesignal.promptOptions.cancelButtonText": "NO THANKS",
    "onesignal.welcomeNotification.title": "Welcome to SaverEurope",
    "onesignal.welcomeNotification.message": "Thanks for subscribing!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Subscribe to notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "You're subscribed to notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "You've blocked notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Thanks for subscribing!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "You won't receive notifications again",
    "onesignal.notifyButton.text.dialogMainTitle": "Manage Site Notifications",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUBSCRIBE",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "UNSUBSCRIBE",
    "onesignal.notifyButton.text.dialogBlockedTitle": "Unblock Notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Follow these instructions to allow notifications:",
  },
  ES: {
    "topheader.compare.help":
      "¿Necesitas ayuda? Echa un vistazo a nuestras guías de vídeo.",
    "carousel.item1.h2": "Ahorra tiempo",
    "carousel.item2.h2": "Ahorra dinero",
    "carousel.item3.h2": "Ahorra para tu futuro",
    "carousel.item1.p":
      "Ahorra tiempo al comparar rápidamente entre proveedores de transferencia de dinero - encuentra el mejor precio y el mejor producto cuando quieras enviar dinero a casa",
    "carousel.item2.p":
      "Aprende a ahorrar dinero al comparar proveedores de transferencia de dinero y al usar las calculadores de SaverEurope",
    "carousel.item3.p":
      "Aprende a ahorrar dinero para tu futuro y a mejorar tus finanzas con la guía básica de finanzas.",
    "button.start": "Comienzo",
    "topnav.compare.form.h1": "Compara tarifas",
    "topheader.compare.form.h2": "Ahorre en transferencias de dinero",
    "topheader.compare.form.p":
      "Compara los costos y servicios por enviar dinero a casa",
    "label.country_from": "Desde el país",
    "label.country_to": "Hasta el país",
    "label.how_much": "¿Cuanto?",
    "button.compare": "Compara",
    "topheader.compare.list.h2": "Resultados de la comparación",
    "topheader.compare.list.green":
      "Los resultados están vigentes hoy, {date_today}.",
    "topheader.compare.list.amber":
      "Los resultados se recopilaron en {date_updated}.",
    "topheader.compare.list.red":
      "Los resultados se recolectaron por un cliente incógnito en  {date_updated}",
    "topheader.compare.list.p": "Enviar dinero desde {{país}} a {{país}}",
    "bottomheader.compare.list.p":
      "Estos resultados puede que no incluyan todos los servicios disponibles para esta ruta de envío. La información anterior incluye todos los costos.",
    "topnav.resource.h1": "Información básica de finanzas",
    "topheader.resource.form.h2": "Información básica de finanzas",
    "topheader.resource.form.p":
      "SaverEurope te ayuda a mejorar tu conocimiento sobre finanzas:",
    "topnav.calculators.h1": "Calculadoras",
    "card1.calculators.h3": "calculadora de pago de horas extras",
    "card1.calculators.p":
      "Si está trabajando en Malasia, puede usar esta calculadora para calcular la cantidad de pago por horas extra que debe recibir.",
    "card2.calculators.h3": "Calculadora de presupuesto",
    "card2.calculators.p":
      "Agrega tu ingreso y tus gatos para calcular cuanto puedes ahorrar y asegurarte de no gastar de más.",
    "breadcrumbs.step": "Paso {step} de {count}",
    "topnav.overtime.h1": "calculadora de pago de horas extras",
    "topheader.overtime.h2":
      "Calculadora de pago de horas extraordinarias de Malasia",
    "topheader.overtime.p":
      "Esta calculadora calculará la cantidad de horas extra que debe recibir según las horas que haya trabajado y los requisitos estándar de horas extra del gobierno.",
    "label.hourly_rate": "Valor hora",
    "label.overtime_hours.h4": "Horas extras",
    "label.overtime_hours.p":
      "De acuerdo con la Ley de Empleo de 1955 (Malasia), se le pagará al empleado a una tarifa no inferior a 1, 5 veces su tarifa por hora por las horas extraordinarias que superen las horas normales de trabajo. Para el trabajo en un día de descanso, la paga no será inferior a 2, 0 veces la tarifa por hora, y en un día festivo no menos de 3, 0 veces la tarifa por hora.",
    "label.basic_wage": "salario mínimo",
    "label.pay_period": "Fecha de pago",
    "label.amount": "Cantidad",
    "label.standard_days": "Días normales (x1.5)",
    "label.rest_days": "Días de descanso (x2)",
    "label.public_holidays": "Vacaciones públicas (x3)",
    "label.paid_public_holidays": "Vacaciones públicas pagadas (x2)",
    "label.unpaid_public_holidays": "Vacaciones públicas sin pagar (x3)",
    "label.overtime": "Horas extras trabajadas",
    "label.total_expense": "Costo total",
    "label.total_income": "Ingreso total",
    "label.money_left": "Dinero restante",
    "label.daily": "Diario",
    "label.weekly": "Semanal",
    "label.monthly": "Mensual",
    "label.item": "Items",
    "label.primary_income": "Ingreso principal",
    "label.market_rate": "El cambio de divisas (FX) del mercado medio es",
    "label.operator": "Operador",
    "label.type": "Forma de transferencia",
    "label.receiving": "Recibiendo",
    "label.rate": "Tarifa por Cambio de Divisa (FX)",
    "label.fee": "Cargos",
    "label.form": "Forma",
    "label.method": "Método de transferencia",
    "label.speed": "Velocidad de la transferencia",
    "label.number_overtime": "Horas extras trabajadas",
    "topnav.faq.h1": "Preguntas frecuentes",
    "topnav.video.h1": "Guías",
    "topheader.video.form.h2": "videos de guía",
    "topheader.video.form.p":
      "Te recomendamos ver nuestros videos de guía para entender cómo funciona la App SaverEurope.",
    "bottom.faq.p":
      "SaverEurope tiene la respuesta a la mayoría de tus preguntas, pero si necesitas más información, por favor contáctanos e intentaremos ayudarte.",
    "notfound.h2": "Oh - Uh",
    "notfound.p": "Perdón, no podemos encontrar la pantalla que busca",
    "topheader.summary.h2": "Resumen",
    "topheader.summary.p":
      "Abajo podrás ver cuánto dinero debes recibir por este período.",
    "label.no_results": "No se encontraron resultados",
    "button.back": "Anterior",
    "button.prev": "Anterior",
    "button.next": "Siguiente",
    "button.remove": "Retirar",
    "button.add_income": "Agregar ingreso",
    "button.add_expense": "Agregar gastos",
    "button.read_more": "Más información",
    "button.show_less": "Muestra menos",
    "topnav.budget.h1": "Calculadora de presupuesto",
    "topheader.income.h2": "Ingresos",
    "topheader.income.p":
      "Comienza con ingresar todas las formas en que ganas dinero",
    "topheader.expense.h2": "Gastos",
    "topheader.expense.p":
      "Agrega tus gastos abajo: E.j: renta, comida, giros, cuentas (teléfono, electricidad, etc.) y viajes.",
    "topheader.budget_summary.p":
      "Abajo podrás ver cuánto dinero debes recibir por este período.",
    "card.other_income.h4": "Otro ingreso",
    "card.other_income.p":
      "¿Obtienes otros beneficios? Ej.: Beneficios gubernamentales, otro trabajo, ingreso por arriendo, acciones, etc.",
    "topnav.setting.h1": "Configuración",
    "label.language": "Idiomas",
    "label.currency": "Moneda",
    "label.push_notification": "Notificaciones Push",
    "label.language.desc": "Selecciona el idioma",
    "label.currency.desc": "Selecciona la moneda de la app",
    "label.push_notification.desc": "Permitir notificaciones push",
    "label.not_supported": "No lo permite",
    "button.view_all_operators": "Ver a todos los operadores",
    "logo.supported.h3": "Con el apoyo de",
    "logo.managed.h3": "Gestionado por",
    "label.view_legend": "Ver pie de foto",
    "label.legend": "Pie de foto",
    "menu.about": "Acerca de nosotros",
    "menu.privacy_policy": "Política de privacidad",
    "menu.terms_and_conditions": "Términos y condiciones",
    "menu.disclaimer": "Aviso legal",
    "label.january": "Enero",
    "label.february": "Febrero",
    "label.march": "Marzo",
    "label.april": "Abril",
    "label.may": "Mayo",
    "label.june": "Junio",
    "label.july": "Julio",
    "label.august": "Agosto",
    "label.september": "Septiembre",
    "label.october": "Octubre",
    "label.november": "Noviembre",
    "label.december": "Diciembre",
    "error.primary_income": "Se requiere un ingreso mínimo",
    "error.basic_wage": "Se requiere un salario mínimo",
    "button.apply": "Aplicar",
    "button.clear": "Limpiar",
    "economic.hardship": "Puede ocurrir una adversidad económica",
    "play.video": "Reproducir vídeo",
    "label.download": "Descargar",
    "label.download_summary": "Descargar resumen",
    "label.phone": "Teléfono",
    "label.fax": "Faz",
    "label.email": "Email",
    "label.website": "Sitio web",
    "button.send_money": "Enviar dinero",
    "label.fx": "Tipo de cambio",
    "label.more_options_available": "Más opciones de transferencia disponibles",
    "label.send_method": "Método para enviar",
    "label.receive_method": "Método para recibir",
    "button.more_details": "Más detalles",
    "label.no_additional_fees": "Sin tarifas adicionales",
    "label.additional_fee": "*Se puede aplicar cargos por recepción",
    "onesignal.promptOptions.actionMessage":
      "Nos gustaría enviarle notificaciones automáticas. Puedes darte de baja en cualquier momento.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "Esta es una notificación de ejemplo.",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Las notificaciones aparecerán en su escritorio",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "Ejemplo de notificación",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Las notificaciones aparecerán en su dispositivo",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(Puede anular su suscripción en cualquier momento)",
    "onesignal.promptOptions.acceptButton": "PERMITIR",
    "onesignal.promptOptions.acceptButtonText": "PERMITIR",
    "onesignal.promptOptions.cancelButton": "NO, GRACIAS",
    "onesignal.promptOptions.cancelButtonText": "NO, GRACIAS",
    "onesignal.welcomeNotification.title": "Bienvenido a SaverEurope",
    "onesignal.welcomeNotification.message": "¡Gracias por suscribirte!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Suscríbete a las notificaciones",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "Estás suscrito a las notificaciones.",
    "onesignal.notifyButton.text.tipStateBlocked":
      "Has bloqueado las notificaciones.",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "¡Gracias por suscribirte!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "No volverás a recibir notificaciones",
    "onesignal.notifyButton.text.dialogMainTitle":
      "Administrar notificaciones del sitio",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUSCRIBIR",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "BAJAR",
    "onesignal.notifyButton.text.dialogBlockedTitle":
      "Desbloquear notificaciones",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Siga estas instrucciones para permitir las notificaciones:",
  },
};
