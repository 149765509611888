import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from "react-router-dom";
import history from "./history";
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga-gtm';

ReactGA.initialize('GTM-P3MTN7K6');

ReactDOM.render(
	<Router history={history}>
		<Route component={App} />
	</Router>,
	document.getElementById("root")
);

serviceWorker.register();
